import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Header = styled.h1`
    margin-top: 80px;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header>404: Not Found</Header>
    <p>Route existiert nicht ...</p>
  </Layout>
)

export default NotFoundPage
